import { useEffect } from "react";
import { Cookies } from "react-cookie";
import { useSearchParams } from "react-router-dom";
import themesConfig from "../utils/themesConfig";

function SSOLogin() {
  const [params] = useSearchParams();
  const cookieClass = new Cookies();

  // in local environment we will use token from Link and in server setup we use token from cookie

  const token =
    process.env.REACT_APP_MODE === "local"
      ? params.get("token")
      : process.env.REACT_APP_MODE === "staging"
      ? cookieClass.get(`login_token_uat`)
      : process.env.REACT_APP_MODE === "production"
      ? cookieClass.get(`login_token_prod`)
      : null;

  useEffect(() => {
    if (token !== undefined && token !== "" && token !== null) {
      localStorage.setItem("user-token", token);
      window.location.replace("/");
    } else {
      localStorage.removeItem("user-token");
      window.location.replace(themesConfig.get_react_app_login_url());
    }
  }, [token]);

  return <div></div>;
}

export default SSOLogin;
