import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchData, postData, postDataLive } from "../../../services/apiFunctions";
import { tradingChartStateTypes } from "../../../types/components/types";
import { RootState } from "../../app/store";

export const fetchGetQuote: any = createAsyncThunk("chartdata/fetchGetQuote", async (data) => {
  const response: any = await postData("get-quote", data);
  return response.data;
});

export const getLastPrice: any = createAsyncThunk("chartdata/lastPrice", async (data) => {
  const response: any = await postData("get-last-price", data);
  return response.data;
});
export const fetchChartData: any = createAsyncThunk("chartdata/fetchChartData", async (data) => {
  const response: any = await postData("chart-data", data);
  return response.data;
});

export const postHoldingTypeChartData: any = createAsyncThunk("chartdata/get-holding-chart-data", async (data) => {
  const response: any = await postData("get-holding-chart-data", data);
  return response.data;
});
export const postTourGuide: any = createAsyncThunk("chartdata/save-tour-guide-page", async (data) => {
  const response: any = await postData("save-tour-guide-page", data);
  return response.data;
});

export const fetchSecurityDetails: any = createAsyncThunk("chartdata/fetchSecurityDetails", async (data: any) => {
  const response: any = await fetchData(`auto-securities?q=${data.name}&asset_type=${data.type}`);

  return response.data;
});
export const addNewSecurityRequest: any = createAsyncThunk("add_new_security_request", async (payload) => {
  const response: any = await postData("add_new_security_request", {
    requestData: payload,
  });
  return response.data;
});
const initialState: tradingChartStateTypes = {
  quote_status: "success",
  holding_typechartdata_status: "success",
  update_quote_price: false,
  securityDet_status: "success",
  search_is_loading: 1,
  chartdata_status: "success",
  bid_price: 0,
  ask_price: 0,
  last_price: 0,
  stream_last_price: 0,
  stream_last_isin: "",
  rate: 0,
  marketId: 6,
  added_watchlist: 0,
  searched_securities: [
    {
      ib_conid: "12087792",
      id: "11",
      iex_symbol: "EURUSD",
      rate_multiply: 1,
      text: "",
    },
  ],
  securityMessage: false,
  search_items: [],
  sd_message: "OK",
  send_new_sec_req: false,
  timeline: "one_month",
  lineTimeline: "1D",
  area_timeline: "1D",
  price_type: 1,
  candleTimeline: "1D",
  chart_type: "area",
  switch_chart_type: "Candlestick",
  chart_data: [],
  candle_chart_data: [],
  is_switch_chart: 1,
  live_candle_chart_data: [],
  security_name: "EURUSD",
  is_live_update: false,
  holding_chart_type_option: "all",
  holding_chart_from_date: "",
  holding_chart_to_date: "",
  holding_type_chart: [],
  holding_type_char_timeline: "1M",
  area_char_timeline: ["1M"],
  area_chart_option_from_date: "",
  area_chart_option_to_date: "",
};

const ChartDataSlice = createSlice({
  name: "chartdata",
  initialState,
  reducers: {
    update_security_message: (state: tradingChartStateTypes, action: PayloadAction<any>) => {
      return {
        ...state,
        securityMessage: action.payload,
      };
    },
    update_is_live_update: (state: tradingChartStateTypes, action: PayloadAction<any>) => {
      return {
        ...state,
        is_live_update: action.payload,
      };
    },
    update_chart_data: (state: tradingChartStateTypes, action: PayloadAction<any>) => {
      return {
        ...state,
        chart_data: action.payload,
      };
    },
    update_new_area_timeline: (state: tradingChartStateTypes, action: PayloadAction<any>) => {
      return {
        ...state,
        area_timeline: action.payload,
      };
    },
    update_switch_chart_type: (state: tradingChartStateTypes, action: PayloadAction<any>) => {
      return {
        ...state,
        switch_chart_type: action.payload,
      };
    },
    update_chart_type: (state: tradingChartStateTypes, action: PayloadAction<any>) => {
      return {
        ...state,
        chart_type: action.payload,
      };
    },
    update_is_switch_chart: (state: tradingChartStateTypes, action: PayloadAction<any>) => {
      // console.log("update_is_switch_chart", action.payload);
      return {
        ...state,
        is_switch_chart: action.payload,
      };
    },
    live_update_candle_chart_data: (state: tradingChartStateTypes, action: PayloadAction<any>) => {
      return {
        ...state,
        live_candle_chart_data: action.payload,
      };
    },
    update_candle_chart_data: (state: tradingChartStateTypes, action: PayloadAction<any>) => {
      if (action.payload.length > 1) {
        console.log("update_candle_chart_data******************", action.payload);
        return {
          ...state,
          candle_chart_data: action.payload,
        };
      } else {
        return {
          ...state,
          candle_chart_data: action.payload.length === 1 ? [...state.candle_chart_data, ...action.payload] : [],
        };
      }

      // if (state.candle_chart_data.length === 0) {
      //   return {
      //     ...state,
      //     candle_chart_data: action.payload.length > 0 ? [...state.candle_chart_data, ...action.payload] : [],
      //   };
      // } else if (state.candle_chart_data.length !== 0 && action.payload.length === 1) {
      //   return {
      //     ...state,
      //     candle_chart_data: action.payload.length > 0 ? [...state.candle_chart_data, ...action.payload] : [],
      //   };
      // }
    },
    clear_candle_chart_data: (state: tradingChartStateTypes, action: PayloadAction<any>) => {
      return {
        ...state,
        candle_chart_data: [],
      };
    },
    update_chartdata_status: (state: tradingChartStateTypes, action: PayloadAction<any>) => {
      return {
        ...state,
        chartdata_status: action.payload,
      };
    },
    update_search_items: (state: tradingChartStateTypes, action: PayloadAction<any>) => {
      return {
        ...state,
        search_items: action.payload,
      };
    },
    update_area_timeline: (state: tradingChartStateTypes, action: PayloadAction<string>) => {
      return {
        ...state,
        timeline: action.payload,
      };
    },
    update_line_timeline: (state: tradingChartStateTypes, action: PayloadAction<string>) => {
      return {
        ...state,
        lineTimeline: action.payload,
      };
    },
    update_candle_timeline: (state: tradingChartStateTypes, action: PayloadAction<string>) => {
      return {
        ...state,
        candleTimeline: action.payload,
      };
    },
    update_bid_prices: (state: tradingChartStateTypes, action: PayloadAction<any>) => {
      // console.log("IEX::update_prices=====", action.payload);
      // console.log("IEX::update_prices=====", action.payload.bid_price);

      return {
        ...state,
        bid_price: Number(state.bid_price) === 0 || action.payload.bid_price ? action.payload.bid_price : 0,
      };
    },
    update_ask_prices: (state: tradingChartStateTypes, action: PayloadAction<any>) => {
      // console.log("IEX::update_prices=====", action.payload);
      // console.log("IEX::update_prices=====", action.payload.bid_price);

      return {
        ...state,

        ask_price: Number(state.ask_price) === 0 || action.payload.ask_price ? action.payload.ask_price : 0,
      };
    },
    update_prices: (state: tradingChartStateTypes, action: PayloadAction<any>) => {
      // console.log("IEX::update_prices=====", action.payload);
      // console.log("IEX::update_prices=====", action.payload.bid_price);

      return {
        ...state,
        bid_price: Number(state.bid_price) === 0 || action.payload.bid_price ? action.payload.bid_price : 0,
        ask_price: Number(state.ask_price) === 0 || action.payload.ask_price ? action.payload.ask_price : 0,
        last_price: Number(state.last_price) === 0 || action.payload.last_price ? action.payload.last_price : 0,
        rate: Number(state.rate) === 0 || action.payload.rate ? action.payload.rate : 0,
      };
    },
    set_is_quote_price: (state: tradingChartStateTypes, action: PayloadAction<any>) => {
      // console.log("IEX::update_prices=====", action.payload);
      // console.log("IEX::update_prices=====", action.payload.bid_price);

      return {
        ...state,
        update_quote_price: action.payload,
      };
    },
    update_holding_chart_type_timeline: (state: tradingChartStateTypes, action: PayloadAction<any>) => {
      // console.log("holding_type_char_timeline", action.payload.name);
      return {
        ...state,
        holding_chart_from_date: action.payload.from_date,
        holding_chart_to_date: action.payload.to_date,
        holding_type_char_timeline: action.payload.name,
      };
    },
    update_holding_chart_type_option: (state: tradingChartStateTypes, action: PayloadAction<any>) => {
      // console.log("action.payload.value", action);
      return {
        ...state,
        holding_chart_type_option: action.payload,
      };
    },
    update_send_new_sec_req: (state: tradingChartStateTypes, action: PayloadAction<any>) => {
      // console.log("action.payload.value", action);
      return {
        ...state,
        send_new_sec_req: false,
      };
    },
    update_area_chart_option: (state: tradingChartStateTypes, action: PayloadAction<any>) => {
      // console.log("area_char_timeline", action.payload.name);
      return {
        ...state,
        area_chart_option: action.payload,
        area_chart_option_from_date: action.payload.from_date,
        area_chart_option_to_date: action.payload.to_date,
        area_char_timeline: action.payload.name,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGetQuote.pending, (state) => {
        return {
          ...state,
          quote_status: "loading",
        };
      })
      .addCase(fetchGetQuote.fulfilled, (state, { payload }) => {
        // console.log("fetchGetQuote Fetched Successfully!");
        if (Number(payload.data.market_id) === 1 || Number(payload.data.market_id) === 6) {
          return {
            ...state,
            // bid_price: payload.data.bid_price,
            // ask_price: payload.data.ask_price,
            // rate: payload.data.rate,
            last_price: payload.data.last_price,
            marketId: payload.data.market_id,
            added_watchlist: payload.data.added_watchlist,
            quote_status: "success",
            update_quote_price: true,
          };
        } else {
          if (state.update_quote_price === false) {
            return {
              ...state,
              //bid_price: 0,
              // ask_price: 0,
              rate: 0,
              last_price: payload.data.last_price,
              marketId: payload.data.market_id,
              added_watchlist: payload.data.added_watchlist,
              quote_status: "success",
              update_quote_price: true,
            };
          }
        }
      })
      .addCase(fetchGetQuote.rejected, (state) => {
        return {
          ...state,
          quote_status: "error",
        };
      })
      .addCase(fetchSecurityDetails.pending, (state) => {
        return {
          ...state,
          securityDet_status: "loading",
          search_is_loading: 2,
          // live_candle_chart_data: [],
          // candle_chart_data: [],
          switch_chart_type: "Candlestick",
          sd_message: "pending",
        };
      })
      .addCase(fetchSecurityDetails.fulfilled, (state, { payload }) => {
        let sendNewSecReq = false;
        if (payload.isFundFound === false && payload.data.length === 0) {
          sendNewSecReq = true;
        }
        return {
          ...state,
          securityDet_status: "success",
          search_is_loading: 3,
          search_items: payload.data,
          sd_message: payload.message,
          send_new_sec_req: sendNewSecReq,
        };
      })
      .addCase(fetchSecurityDetails.rejected, (state) => {
        return {
          ...state,
          securityDet_status: "error",
          search_is_loading: 4,
          search_items: [],
          sd_message: "rejected",
        };
      })

      .addCase(getLastPrice.pending, (state) => {
        return {
          ...state,
          chartdata_status: "loading",
          // live_candle_chart_data: [],
          // candle_chart_data: [],
        };
      })
      .addCase(getLastPrice.fulfilled, (state, { payload }) => {
        if (Number(payload.last_price) !== 0) {
          if (payload.from === "stream") {
            return {
              ...state,
              stream_last_price: payload.last_price,
              stream_last_isin: payload.isin_code,
              chartdata_status: "success",
            };
          } else {
            return {
              ...state,
              last_price: payload.last_price,
              chartdata_status: "success",
            };
          }
        }
      })
      .addCase(getLastPrice.rejected, (state) => {
        return {
          ...state,
          chartdata_status: "error",
        };
      })
      .addCase(fetchChartData.pending, (state) => {
        return {
          ...state,
          chartdata_status: "loading",
          // live_candle_chart_data: [],
          // candle_chart_data: [],
        };
      })
      .addCase(fetchChartData.fulfilled, (state, { payload }) => {
        if (Number(payload.market_id) === 5 || Number(payload.market_id) === 1) {
          return {
            ...state,

            chart_type: payload.chart_type,
            security_name: payload.security_name,
            marketId: payload.market_id,
            chartdata_status: "success",
          };
        } else {
          if (Number(payload.market_id) === 8) {
            return {
              ...state,
              chart_type: payload.chart_type,
              chart_data: payload.data?.chart_data,
              security_name: payload.security_name,
              marketId: payload.market_id,
              last_price: payload.last_price,
              chartdata_status: "success",
            };
          } else {
            return {
              ...state,
              chart_type: payload.chart_type,
              chart_data: payload.data?.chart_data,
              security_name: payload.security_name,
              marketId: payload.market_id,
              chartdata_status: "success",
            };
          }
        }
      })
      .addCase(fetchChartData.rejected, (state) => {
        return {
          ...state,
          chartdata_status: "error",
        };
      })
      .addCase(postHoldingTypeChartData.pending, (state) => {
        return {
          ...state,
          holding_typechartdata_status: "loading",
        };
      })
      .addCase(postHoldingTypeChartData.fulfilled, (state, { payload }) => {
        // console.log("postHoldingTypeChartData", payload);
        return {
          ...state,
          holding_type_chart: payload.data,
          holding_typechartdata_status: "success",
        };
      })
      .addCase(postHoldingTypeChartData.rejected, (state) => {
        return {
          ...state,
          holding_typechartdata_status: "error",
        };
      })
      .addCase(postTourGuide.pending, (state) => {
        return {
          ...state,
        };
      })
      .addCase(postTourGuide.fulfilled, (state, { payload }) => {
        // console.log("postTourGuide", payload);
        return {
          ...state,
        };
      })
      .addCase(postTourGuide.rejected, (state) => {
        return {
          ...state,
        };
      })
      //START addNewSecurityRequest
      .addCase(addNewSecurityRequest.rejected, () => {})
      .addCase(addNewSecurityRequest.pending, () => {})
      .addCase(addNewSecurityRequest.fulfilled, (state, { payload }) => {
        // console.log("updateLayoutInUserLayouts:: updatedLayouts", updatedLayouts);
        return {
          ...state,
          search_items: payload.data,
          securityMessage: payload.data.length,
        };
      });
    //END addNewSecurityRequest
  },
});

export const {
  update_security_message,
  update_is_live_update,
  update_chart_data,
  update_chart_type,
  update_switch_chart_type,
  update_is_switch_chart,
  live_update_candle_chart_data,
  update_candle_chart_data,
  clear_candle_chart_data,
  update_chartdata_status,
  update_area_timeline,
  update_new_area_timeline,
  update_line_timeline,
  update_candle_timeline,
  update_prices,
  update_ask_prices,
  update_bid_prices,
  update_search_items,
  set_is_quote_price,
  update_holding_chart_type_timeline,
  update_holding_chart_type_option,
  update_area_chart_option,
  update_send_new_sec_req,
} = ChartDataSlice.actions;

export const name = (state: RootState) => state.chartdata.security_name;
export const bid_price = (state: RootState) => state.chartdata.bid_price;
export const ask_price = (state: RootState) => state.chartdata.ask_price;
export const last_price = (state: RootState) => state.chartdata.last_price;
export const stream_last_price = (state: RootState) => state.chartdata.stream_last_price;
export const stream_last_isin = (state: RootState) => state.chartdata.stream_last_isin;
export const rate = (state: RootState) => state.chartdata.rate;
export const marketId = (state: RootState) => state.chartdata.marketId;
export const added_watchlist = (state: RootState) => state.chartdata.added_watchlist;

export default ChartDataSlice.reducer;
